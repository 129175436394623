.projects-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: fit-content;
  min-height: 90vh;
  flex-direction: column;
}

.projects-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 90vw;
  height: fit-content;
  padding: 20px;
}

.card {
  padding: 20px;
  width: 330px;
  min-height: 370px;
  max-width: 90%;
  border-radius: 20px;
  background: #e8e8e8;
  box-shadow: 5px 5px 6px #dadada, -5px -5px 6px #f6f6f6;
  transition: 0.4s;
  margin: 10px;
}

.card:hover {
  translate: 0 -10px;
}

.card-title {
  font-size: 18px;
  font-weight: 600;
  color: #2e54a7;
  margin: 15px 0 0 10px;
}

.card-image {
  min-height: 170px;
  background-color: #c9c9c9;
  border-radius: 15px;
  box-shadow: inset 8px 8px 10px #c3c3c3, inset -8px -8px 10px #cfcfcf;
  aspect-ratio: 16/9;
  width: 330px;
  max-width: 100%;
}

.card-body {
  margin: 13px 0 0 10px;
  color: rgb(31, 31, 31);
  font-size: 15px;
}

.footer {
  float: right;
  margin: 28px 0 0 18px;
  font-size: 16px;
  color: #636363;
  display: flex;
  align-items: center;
  justify-content: center;
}

.by-name {
  font-weight: 700;
  cursor: pointer;
}

.MuiPaper-root {
  width: 95vw !important;
  max-width: 95vw !important;
}

.slider-image {
  /* width: 100%;
  height: auto;
  aspect-ratio: auto; */
  width: 100%;
  height: fit-content;
  aspect-ratio: auto;
  margin: 10px auto;
  object-fit: contain;
}

.images-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
