.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: fit-content;
  min-height: 80vh;
  /* background-color: rgb(221, 238, 250); */
}

.gallery-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  margin: 10px auto;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: fit-content;
  flex-wrap: wrap;
}

.image-container > img {
  width: 200px;
  height: 200px;
  aspect-ratio: auto;
  margin: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.MuiDialogContent-root {
  width: 95vw !important;
  height: fit-content !important;
  max-height: 95vh !important;
}

.MuiPaper-root {
  width: 95vw !important;
  max-width: 95vw !important;
}

.zoomed-image {
  width: 100%;
  aspect-ratio: auto;
  margin: 10px auto;
  object-fit: contain;
}

.MuiButtonBase-root {
  z-index: 10 !important;
  right: 0px !important;
  top: -5px !important;
}

.gallery-header > span {
  font-size: 28px;
  font-weight: 600;
  color: rgb(26, 43, 86);
  margin-right: 10px;
  cursor: pointer;
}

.active-type {
  color: rgb(252, 90, 90) !important;
}

.slider-image {
  width: 100%;
  height: auto;
  aspect-ratio: auto;
}

.no-photos {
  width: 90vw;
  height: fit-content;
  text-align: center;
  color: rgb(252, 90, 90);
  margin: 120px auto;
  font-size: 1.5rem;
}

@media only screen and (width < 768px) {
  .gallery-header > span {
    font-size: 12px;
  }
  .image-container > img {
    width: 100px;
    height: 100px;
  }
  .no-photos {
    font-size: 1rem;
  }
}
