.chatbot {
  position: fixed;
  bottom: 60px;
  right: 10px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid rgb(26, 43, 86);
  box-shadow: 1px 1px 10px 1px rgba(26, 43, 86, 0.5);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
}

.chatbot > .MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
  color: rgb(26, 43, 86) !important;
}

.options-wrapper {
  position: fixed;
  bottom: 100px;
  right: 10px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid rgb(26, 43, 86);
  box-shadow: 1px 1px 10px 1px rgba(26, 43, 86, 0.5);
  width: 30px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 100;
}

.options-wrapper > .MuiSvgIcon-root,
a > .MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
  color: rgb(26, 43, 86) !important;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  background-color: white !important;
}

.options-wrapper > .MuiSvgIcon-root:hover,
a > .MuiSvgIcon-root:hover {
  background-color: rgb(26, 43, 86) !important;
  color: white !important;
}

.options-wrapper > a,
a:visited,
a:active {
  text-decoration: none;
  color: rgb(26, 43, 86) !important;
}
