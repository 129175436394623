* {
  margin: 0;
  padding: 0;
  outline-width: 2px;
  outline-offset: 3px;
  outline-color: blue;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*:-webkit-scrollbar {
  display: none;
}

body {
  width: 100%;
  max-width: 100vw;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.App {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.pages-wrapper {
  min-height: 90vh;
  max-width: 100vw;
}

.header-wrapper {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  box-shadow: 1px 1px 10px 1px rgba(26, 43, 86, 0.5);
  background-color: #fff;
  position: sticky;
  top: 0;
}

.header-logo {
  width: 150px;
  height: 100px;
  z-index: 100;
  object-fit: contain;
  margin-left: 10px;
  cursor: pointer;
}

.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: fit-content;
  height: 50px;
}

.burger-dropdown {
  position: fixed;
  top: 90px;
  right: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 120px;
  height: 150px;
  border: 1px solid rgb(26, 43, 86);
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 1px 10px 1px rgba(26, 43, 86, 0.5);
  z-index: 200;
}

.burger-dropdown > span {
  padding-left: 10px;
  padding-top: 10px;
  width: 92% !important;
  height: 50px;
}

.nav-wrapper,
.burger-dropdown > span {
  color: rgb(26, 43, 86);
  cursor: pointer;
  width: fit-content;
  font-weight: 600;
}

.nav-wrapper > span {
  margin-right: 20px;
}

.burger-dropdown > span:hover {
  background-color: whitesmoke;
}

.nav-wrapper > span > .MuiSvgIcon-root {
  width: 30px !important;
  height: 30px !important;
}

.home-wrapper {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.home-wrapper > h2 {
  margin-top: 20px;
}

.main-wrapper {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  position: relative;
}

.main-wrapper > img {
  width: 100%;
  object-fit: contain;
}

.banner--fadeBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(265, 265, 265, 0.01),
    rgba(26, 43, 86, 0.75)
  );
}

.main-wrapper > button {
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  transform: translate(-50%, -7.5%);
  width: fit-content;
  height: fit-content;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  color: rgb(26, 43, 86);
  background-color: rgb(265, 265, 265);
  border: 2px solid rgb(26, 43, 86);
  border-radius: 5px;
  box-shadow: 1px 1px 10px 1px rgba(26, 43, 86, 0.5);
  cursor: pointer;
  z-index: 90;
}

.main-wrapper > button:hover {
  color: rgb(265, 265, 265);
  background-color: rgba(26, 43, 86, 0.5);
  border: 2px solid rgb(265, 265, 265);
}

.footer-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -1px -1px 10px 1px rgba(26, 43, 86, 0.5);
  background-color: whitesmoke;
  color: rgb(26, 43, 86);
  font-weight: 600;
  font-size: 20px;
  z-index: 10;
}

.about-us-wrapper {
  padding: 30px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-us-wrapper > h1,
article,
h2,
p {
  width: 85%;
  max-width: 1500px;
}

.about-us-wrapper > h1 {
  font-size: 26px;
  color: rgb(26, 43, 86);
}

.about-us-wrapper > article {
  font-size: 18px;
  text-align: justify;
}

.contact-us-wrapper {
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 85vw;
  height: fit-content;
}

.contact-us-wrapper > section {
  width: 80vw;
  max-width: 600px;
  height: 300px;
  padding: 20px;
  border: 2px dashed rgb(26, 43, 86);
  border-radius: 10px;
  margin: 20px;
}

.contact-us-wrapper > section > iframe {
  height: 100%;
  width: 100%;
  max-width: 600px;
  border: 0;
}

.contact-us-wrapper > section > p,
a {
  font-size: 18px;
}

h2 {
  color: rgb(26, 43, 86);
}

/* Login */

.login-wrapper {
  height: fit-content;
  width: fit-content;
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.login-logo {
  width: 250px;
  aspect-ratio: 16/9;
}

.login-button {
  max-width: 320px;
  display: flex;
  padding: 0.5rem 1.4rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  gap: 0.75rem;
  color: rgb(65, 63, 63);
  background-color: #fff;
  cursor: pointer;
  transition: all 0.6s ease;
  cursor: pointer;
  margin-top: 30px;
}

.login-button svg {
  height: 24px;
}

login-button:hover {
  transform: scale(1.02);
}

/* Admin */

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  height: fit-content;
  padding: 10px;
  border: 1px solid rgb(26, 43, 86);
  border-radius: 5px;
  margin-top: 20px;
  padding-left: 5%;
}

.form-wrapper > .MuiFormControl-root {
  width: 90% !important;
}

.whatsapp-logo {
  position: fixed;
  bottom: 110px;
  right: 10px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid rgb(26, 43, 86);
  box-shadow: 1px 1px 10px 1px rgba(26, 43, 86, 0.5);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
}

.whatsapp-logo > .MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
  color: rgb(26, 43, 86) !important;
}

.cards-wrapper {
  width: 90%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card-wrapper {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 10px auto;
  text-align: center;
}

.MuiAccordionSummary-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.MuiAccordionSummary-content > img {
  width: 80px;
  height: 80px;
}

.MuiAccordionSummary-content > p {
  font-weight: 600;
}

.MuiAccordionDetails-root > ul > li {
  text-align: left;
  margin-left: 15vw;
}

@media screen and (max-width: 1024px) {
  .main-wrapper > button {
    font-size: 16px;
    width: 250px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .header-wrapper {
    width: 100%;
    height: 85px;
  }
  .header-logo {
    width: 100px;
    height: 85px;
  }
  .home-wrapper > h2 {
    font-size: 16px;
  }
  .card-image {
    width: 75px !important;
  }
  .card-wrapper > span {
    width: 75px;
    font-size: 12px;
    margin-top: -35px;
  }
}
