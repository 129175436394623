.contact-form {
  width: 100%;
  height: 100%;
  background-color: rgba(26, 43, 86, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form-wrapper {
  width: fit-content;
  height: fit-content;
  padding: 10px;
  background-color: #fff;
}

.header-section {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
  flex-direction: row !important;
}

.header-section > .MuiSvgIcon-root {
  cursor: pointer;
}

.MuiFormControl-root {
  margin: 15px 5px !important;
}

.book-button {
  width: 100%;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  color: rgb(26, 43, 86);
  background-color: rgb(265, 265, 265);
  border: 2px solid rgb(26, 43, 86);
  border-radius: 5px;
  box-shadow: 1px 1px 10px 1px rgb(26, 43, 86);
  cursor: pointer;
  margin-top: 20px !important;
}

.book-button:hover {
  color: rgb(265, 265, 265);
  background-color: rgb(26, 43, 86);
  border: 2px solid rgb(265, 265, 265);
}

.disclaimer-text {
  font-size: 14px;
  font-style: italic;
  margin-top: 10px !important;
}

@media screen and (max-width: 1024px) {
  .book-button {
    font-size: 14px;
  }
  .disclaimer-text {
    font-size: 12px;
  }
}
